.list-styled,
.list-styled > li {
  @apply relative before:bg-secondary before:absolute before:w-2 before:h-2 before:rounded-full before:top-2 before:-left-4;
}

.list-styled.black > li {
  @apply relative before:bg-black;
}

.list-normal {
  @apply pl-[18px] !list-disc !list-outside;
}

.list-check > li,
.list-numbered > li {
  @apply flex flex-row items-center !mb-[15px] lg:!mb-5 text-[14px] md:text-[16px];
}

.list-check.large > li,
.list-numbered.large > li {
  @apply text-[16px] md:text-[20px];
}
.list-check > li::before {
  @apply content-[''] bg-[url("/img/icons/list-check.svg")] bg-contain bg-no-repeat mr-[10px] lg:mr-[20px] bg-mostlyGreen inline-block w-[25px] h-[25px] md:w-[35px] md:h-[35px] rounded-full shrink-0 self-center;
}

.list-check.large > li::before {
  @apply shrink-0 bg-cover bg-no-repeat mr-[10px] md:mr-[20px] md:w-[50px] md:h-[50px] w-[25px] h-[25px] rounded-full;
}

.list-check.focus > li {
  @apply flex items-center !mb-[15px] lg:!mb-5 text-[14px] lg:text-[20px];
}

.list-check.focus > li::before {
  @apply shrink-0 content-[''] bg-[url("/img/icons/list-check-focus.svg")] bg-cover bg-no-repeat mr-[5px] md:mr-[10px] bg-white inline-block lg:w-[50px] lg:h-[50px] w-[30px] h-[30px] rounded-full;
}

.list-numbered {
  @apply list-decimal;
}

.list-numbered > li {
  counter-increment: customListCounter;
}

.list-numbered > li:first-child {
  counter-reset: customListCounter;
}

.list-numbered > li::before {
  content: counter(customListCounter) ' ';
  @apply flex flex-row justify-center items-center text-white text-[14px] md:text-[16px] leading-[30px] mr-[10px] lg:mr-[20px] bg-mostlyGreen w-[35px] h-[35px] rounded-full shrink-0;
}

.list-numbered.large > li::before {
  @apply text-[16px] md:text-[20px] w-[35px] h-[35px] md:w-[50px] md:h-[50px];
}

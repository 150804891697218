.flow-stepper {
  @apply flex flex-col lg:flex-row gap-5 lg:gap-10;
}

.flow-stepper-step {
  @apply flex-1 flex flex-row items-center gap-[10px] py-5 px-[15px];
}

.flow-stepper-icon {
  @apply flex flex-row items-center justify-center border-[3px] rounded-full w-9 h-9;
}

.flow-stepper-step.default:hover {
  @apply text-primary;
}

.flow-stepper-step.default:hover,
.flow-stepper-step.complete:hover {
  @apply bg-lightGrey;
}

.flow-stepper-step.default {
  @apply border border-mostlyGreen rounded-[5px] text-mostlyGreen;
}

.flow-stepper-step.default > .flow-stepper-icon {
  @apply border-mostlyGreen bg-white text-mostlyGreen;
}

.flow-stepper-step.complete {
  @apply border border-[#eeeeee] rounded-[5px] text-mostlyGreen;
}

.flow-stepper-step.complete > .flow-stepper-icon {
  @apply border-mostlyGreen bg-mostlyGreen text-white;
}

.flow-stepper-step.disabled {
  @apply text-[#aaaaaa] border rounded-[5px] border-[##EEEEEE];
}

.flow-stepper-step.disabled > .flow-stepper-icon {
  @apply bg-lightGrey border-[#B5B6AB] text-[#B5B6AB];
}

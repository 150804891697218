/* Button */

.button {
  @apply inline-flex items-center justify-center relative box-border outline-none outline-0 m-0 cursor-pointer select-none align-middle appearance-none no-underline font-body font-medium text-sm leading-[1.75] min-w-[64px] transition-colors duration-[250ms] ease-in-out shadow-none rounded-full normal-case focus:shadow-none focus-visible:outline-none;
}

/*
.button:focus {
   @apply !outline outline-1 outline-red-300; 
} 
this is commented for now until we know what we want for the focus states of buttons */

/* Contained */
.button-contained {
  @apply py-1.5 px-4 border-0;
}
.button-primary.button-contained {
  @apply bg-primary text-white hover:bg-primaryDark;
}
.button-secondary.button-contained {
  @apply bg-secondary text-ivoryBlack hover:bg-secondaryDark;
}
.button-tertiary.button-contained {
  @apply bg-tertiary text-ivoryBlack hover:bg-tertiaryDark;
}
.button-white.button-contained {
  @apply bg-white text-davyGrey;
}
.button-light.button-contained {
  @apply bg-white text-davyGrey;
}
.button-iron.button-contained {
  @apply bg-iron text-ivoryBlack;
}
.button-grey.button-contained {
  @apply bg-alabaster text-primary hover:bg-alabaster/[.4];
}

/* Outlined  */
.button-outlined {
  @apply py-[5px] px-[15px] bg-transparent;
}
.button-primary.button-outlined {
  @apply text-primary border border-primary/50 hover:border-primary hover:bg-primary/[.04];
}
.button-secondary.button-outlined {
  @apply text-secondary border border-secondary/50 hover:border-secondary hover:bg-secondary/[.04];
}
.button-tertiary.button-outlined {
  @apply text-tertiary border border-tertiary/50 hover:border-tertiary hover:bg-tertiary/[.04];
}
.button-white.button-outlined {
  @apply text-white border border-white/50 hover:border-white hover:bg-white/[.04];
}
.button-light.button-outlined {
  @apply text-davyGrey border border-iron hover:bg-[#eeeeee];
}
.button-iron.button-outlined {
  @apply text-white border border-white/50 hover:border-white hover:bg-white/[.04];
}

/* Inverted */
.button-primary.button-inverted {
  @apply bg-white text-primary hover:bg-white/[.70];
}
.button-secondary.button-inverted {
  @apply bg-ivoryBlack text-secondary hover:bg-ivoryBlack/[0.04];
}
.button-tertiary.button-inverted {
  @apply bg-ivoryBlack text-tertiary hover:bg-ivoryBlack/[0.04];
}
.button-white.button-inverted {
  @apply bg-primary text-white hover:bg-primary/[.04];
}
.button-light.button-inverted {
  @apply bg-iron text-davyGrey hover:bg-iron/[.04];
}
.button-iron.button-inverted {
  @apply bg-primary text-white hover:bg-primary/[.04];
}
.button-grey.button-inverted {
  @apply bg-primary text-alabaster hover:bg-primary/[.04];
}

/* Text */
.button-text {
  @apply p-0 border-0 outline-none underline hover:no-underline;
}
.button-primary.button-text {
  @apply bg-transparent border-none text-primary;
}
.button-secondary.button-text {
  @apply bg-transparent border-none text-secondary;
}
.button-tertiary.button-text {
  @apply bg-transparent border-none text-tertiary;
}
.button-white.button-text {
  @apply bg-transparent border-none text-[#eeeeee] hover:!text-white;
}
.button-light.button-text {
  @apply bg-transparent border-none text-davyGrey;
}
.button-iron.button-text {
  @apply bg-transparent border-none text-alabaster;
}
.button-disabled {
  @apply text-[rgba(0,0,0,0.26)] pointer-events-none cursor-default shadow-none !important;
}
.button-disabled.button-contained {
  @apply bg-[rgba(0,0,0,0.12)];
}
.button-disabled.button-outlined {
  @apply border-[rgba(0,0,0,0.12)];
}

.button-large {
  @apply md:text-base md:leading-5 py-[12px] md:py-[14px] md:px-5 font-semibold;
}

.button-small {
  @apply px-5 py-2 text-sm font-medium leading-4;
}

.button-medium {
  @apply md:text-[16px] md:leading-5 md:py-3 md:px-5;
}

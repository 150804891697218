.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 5px;
  background-color: currentColor;
  outline: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 9999px;
  @apply w-5 h-5 md:w-[35px] md:h-[35px];
  background: currentColor;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  @apply w-5 h-5 md:w-[35px] md:h-[35px];
  background: currentColor;
  cursor: pointer;
}
